<template>
  <v-container>
    <h6 class="text-h6">Products</h6>
    <p class="text-subtitle-1 font-weight-bold">
      Got questions? Call or Whatsapp {{ siteData.phone }}
    </p>
    <v-sheet rounded="lg" color="white" class="pa-4 px-10 mt-2">
      <v-row align="stretch" class="my-2 py-2">
        <template v-for="card in cards">
          <v-col
            :key="card.title"
            v-if="!card.disabled.includes(userCountry)"
            cols="12"
            :md="getColLength"
          >
            <mini-cards
              :link="card.href"
              :image="card.image"
              :title="card.title"
              :message="card.message"
              :btnTitle="card.btnTitle"
              :disabled="card.status === false"
            />
          </v-col>
        </template>
      </v-row>
    </v-sheet>
  </v-container>
</template>

<script>
import card from '../../../assets/img/cards.341766b6.png'
// import refill from '../../../assets/img/refill.fe9b7b69.png'
import cryto from '../../../assets/img/cryto.png'
import miniCards from '../../../components/dashboard/cards/miniCards.vue'

import PayBillImage from '../../../assets/img/pay-your-bills.jpeg'
import AirtimeToCashImage from '../../../assets/img/airtime-to-cash.jpeg'
import { mapState } from 'vuex'
import { COUNTRIES } from '../../../utils/countryHelpers'
export default {
  components: { miniCards },
  data: () => ({}),
  created() {
    this.$store.dispatch('user/getSiteSettings')
  },
  computed: {
    ...mapState({
      siteData: (state) => state.user.siteSettings,
      getColLength() {
        switch (this.userCountry) {
          case COUNTRIES.GH:
            return '6'
          case COUNTRIES.KE:
            return '6'
          default:
            return '4'
        }
      }
    }),
    cards() {
      return [
        {
          image: card,
          title: 'Giftcards',
          href: '/user/products/giftcards',
          btnTitle: 'Sell Now',
          disabled: [],
          status: true
        },
        {
          image: cryto,
          title: 'Cryptocurrency',
          href: '/user/products/crypto',
          disabled: [],
          status: true
        },
        {
          image: PayBillImage,
          title: 'Pay Bills',
          href: '/user/products/bill-payment',
          btnTitle: 'Pay Now',
          disabled: [COUNTRIES.GH, COUNTRIES.KE],
          status: true
        },
        {
          image: AirtimeToCashImage,
          title: 'Airtime to Cash',
          href: '/user/products/bills/convert-airtime/',
          message: this.siteData.airtimeToCashFeatureMessage,
          btnTitle: 'Convert Now',
          disabled: [COUNTRIES.GH, COUNTRIES.KE],
          status: this.siteData.airtimeToCashFeatureAvailable
        }
      ]
    }
  }
}
</script>

<style></style>
